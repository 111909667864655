<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
      <h2>Dettagli Intervento Turno  Reperibilità</h2>

      <div fxLayout="column">
          <form [formGroup]="form" fxLayout="column" class="responsive-mat-form" >

              <!-- Id field -->
              <mat-form-field>
                  <input matInput formControlName="id" placeholder="Intervento Id" />
              </mat-form-field>

              <!-- Collaborattore field -->
              <mat-form-field fxFlex="100" fxFlex.md="85" fxFlex.sm="75" fxFlex.xs="75" >
                <mat-select name="collaboratore" [disabled]="!isJappTenantAdmin()" [compareWith]="compareDTO"
                  formControlName="collaboratore" placeholder="Scegli il collaboratore"
                  (selectionChange)="onFilterChange()">
                  <mat-option *ngFor="let collaboratore of collaboratori" readonly="true" [value]="collaboratore">
                    {{collaboratore.nome}} {{collaboratore.cognome}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('collaboratore').errors?.required">
                  Campo obbligatorio
                </mat-error>
              </mat-form-field>
             

              <!-- Tipo Servizio field -->
              <mat-form-field *ngIf="interventoId==0 ">
                  <mat-select name="servizio" [compareWith]="compareDTO" formControlName="tipoServizio"
                      placeholder="Scegli il tipo di servizio" 
                      (selectionChange)="onFilterChange()">
                      <mat-option [value]="null" readonly="true"></mat-option>
                      <mat-option *ngFor="let servizio of servizi" readonly="true" [value]="servizio">
                          {{servizio.descrizione}} - {{servizio.numeroReperibilita.mainNumber}}
                      </mat-option>
                  </mat-select>
                  <mat-error *ngIf="form.get('tipoServizio').errors?.required">
                      Campo obbligatorio
                  </mat-error>
              </mat-form-field>
              <mat-checkbox
              (change)="onMostraFilter()">
              {{(!mostraFiltroFlag)? 'Maggiori Filtri' : 'Nascondi Filtri'}}
              </mat-checkbox>

              <!-- DataDa field -->
              <mat-form-field  *ngIf="interventoId==0 && mostraFiltroFlag"
                  fxFlex="25"
                  fxFlex.md="100"
                  fxFlex.sm="100"
                  fxFlex.xs="100"
                  [ngStyle]="{'padding-right': 2 + '%'}" 
                  [ngStyle.md]="{'padding-right.px': 0}"
                  [ngStyle.sm]="{'padding-right.px': 0}" 
                  [ngStyle.xs]="{'padding-right.px': 0}"
              >
                  <input matInput (click)="picker.open()" [matDatepicker]="picker"
                      formControlName="dataDa" placeholder="Scegli data da"
                      [disabled]="!isJappTenantAdmin()"
                      (dateInput)="onFilterChange()">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker ></mat-datepicker>
                  
                  <mat-error *ngIf="form.get('dataDa').errors?.required">
                      Campo obbligatorio
                  </mat-error>
              </mat-form-field>
             <!-- DataA field -->

            <mat-form-field *ngIf="interventoId==0 && mostraFiltroFlag"
              fxFlex="25" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
              [ngStyle]="{'padding-right': 2 + '%'}" 
              [ngStyle.md]="{'padding-right.px': 0}"
              [ngStyle.sm]="{'padding-right.px': 0}" 
              [ngStyle.xs]="{'padding-right.px': 0}"
                           >
              <input matInput (click)="picker1.open()" 
                  [matDatepicker]="picker1"
                  formControlName="dataA" 
                  placeholder="Scegli data a" 
                  [min]="form.get('dataDa').value"
                  [max]="MAXDATE"
                  (dateInput)="onFilterChange()"
                  [disabled]="!isJappTenantAdmin()">
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
              <mat-error *ngIf="form.get('dataA').errors?.required">
                  Campo obbligatorio
              </mat-error>
            </mat-form-field>
             <!-- Turno field -->
            <mat-form-field  *ngIf="(!!turni && turni.length>0|| interventoId!=0)">
                <mat-select name="turno" [compareWith]="compareDTO" formControlName="turno"
                    placeholder="Scegli il turno" >
                    <mat-option [value]="null" readonly="true"></mat-option>
                    <mat-option *ngFor="let turno of turni" readonly="true" [value]="turno">
                 {{turno.servizio.descrizione}}  -  {{turno.dataDa | date: 'medium'}} - {{turno.dataA | date: 'medium'}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('tipoServizio').errors?.required">
                    Campo obbligatorio
                </mat-error>
            </mat-form-field>



             <!-- InterventoDataDa field -->
            <mat-form-field  *ngIf="(turni && turni.length>0|| interventoId!=0) && isTurnoPresentOrPast() "
            fxFlex="25"
            fxFlex.md="100"
            fxFlex.sm="100"
            fxFlex.xs="100"
            [ngStyle]="{'padding-right': 2 + '%'}" 
            [ngStyle.md]="{'padding-right.px': 0}"
            [ngStyle.sm]="{'padding-right.px': 0}" 
            [ngStyle.xs]="{'padding-right.px': 0}"
        >
            <input matInput (click)="pickerInterventoDataDa.open()" [matDatepicker]="pickerInterventoDataDa"
                formControlName="InterventoDataDa" placeholder="Scegli Data inizio Intervento"
                [min]="minInterventoDateValidator()"
                [max]="  maxInterventoDateValidator()">
            <mat-datepicker-toggle matSuffix [for]="pickerInterventoDataDa"></mat-datepicker-toggle>
            <mat-datepicker #pickerInterventoDataDa ></mat-datepicker>
            
            <mat-error *ngIf="form.get('InterventoDataDa').errors?.required">
                Campo obbligatorio
            </mat-error>
        </mat-form-field>






              <!-- ora da field -->
              <mat-form-field  *ngIf="(turni && turni.length>0 || interventoId!=0) && isTurnoPresentOrPast() "
                  fxFlex="25"
                  fxFlex.md="100"
                  fxFlex.sm="100"
                  fxFlex.xs="100"
                  [ngStyle]="{'padding-right': 2 + '%'}" 
                  [ngStyle.md]="{'padding-right.px': 0}"
                  [ngStyle.sm]="{'padding-right.px': 0}" 
                  [ngStyle.xs]="{'padding-right.px': 0}"
              >
                  <input matInput [min]="minTimePickerValidatorInizioIntervento()" [max]="maxTimePickerValidatorInizioIntervento()"
                      [ngxTimepicker]="toggleTimepickerA" [format]="24" formControlName="orarioDaIntervento"
                      placeholder="Orario Inizio Intervento">
                  <ngx-material-timepicker #toggleTimepickerA [preventOverlayClick]="false" [minutesGap]="timePickerGap"
                      hoursOnly="false">
                  </ngx-material-timepicker>
              </mat-form-field>

              
             <!-- InterventoDataA field -->
            <mat-form-field  *ngIf="(turni && turni.length>0 || interventoId!=0) && isTurnoPresentOrPast()"
            fxFlex="25"
            fxFlex.md="100"
            fxFlex.sm="100"
            fxFlex.xs="100"
            [ngStyle]="{'padding-right': 2 + '%'}" 
            [ngStyle.md]="{'padding-right.px': 0}"
            [ngStyle.sm]="{'padding-right.px': 0}" 
            [ngStyle.xs]="{'padding-right.px': 0}"
        >
            <input matInput (click)="pickerInterventoDataA.open()" [matDatepicker]="pickerInterventoDataA"
                formControlName="InterventoDataA" placeholder="Scegli Data Fine Intervento"
                [min]="minInterventoDateValidator()"
                [max]="maxInterventoDateValidator()">
            <mat-datepicker-toggle matSuffix [for]="pickerInterventoDataA"></mat-datepicker-toggle>
            <mat-datepicker #pickerInterventoDataA ></mat-datepicker>
            
            <mat-error *ngIf="form.get('InterventoDataA').errors?.required">
                Campo obbligatorio
            </mat-error>
        </mat-form-field>

              <!-- Ora Fine  field -->
              <mat-form-field  *ngIf="(turni && turni.length>0 || interventoId!=0) && isTurnoPresentOrPast()  "
                  fxFlex="25" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                  [ngStyle]="{'padding-right.px': 0}"
                  
              >
                  <input matInput [min]="minTimePickerValidatorFineIntervento()" [ngxTimepicker]="toggleTimepicker" [format]="24"
                      formControlName="orarioAIntervento" placeholder="Orario Fine Intervento"
                      [max]="maxTimePickerValidatorFineIntervento()">
                  <ngx-material-timepicker #toggleTimepicker  [preventOverlayClick]="false" [minutesGap]="timePickerGap"
                      hoursOnly="false">
                  </ngx-material-timepicker>
              </mat-form-field>


              <mat-card *ngIf="!isTurnoPresentOrPast()&& !!form.get('turno').value">
                Scegliere Un turno Presente o Passato

              </mat-card>
              <mat-card *ngIf="turni && turni.length==0 && interventoId ==0">
                Nessun turno di reperibilità è stato trovato per questo collaboratore e questi filtri

              </mat-card>


            <!-- Campo descrizione -->
            <mat-form-field fxlayout="column" class="example-full-width"  *ngIf="(turni && turni.length>0|| interventoId!=0) && isTurnoPresentOrPast()">
                    <mat-label>Descizione</mat-label>
                <textarea cdkTextareaAutosize matInput rows="8" cols="10" name="descrizione" formControlName="descrizione"
                placeholder="Descrizione del intervento" class="textArea"></textarea>
            
                </mat-form-field>



       
              <!-- Campi di informazione operazione -->
              <mat-expansion-panel class="mat-elevation-z0">
                  <mat-expansion-panel-header>
                      <mat-panel-title>
                          Dettagli operazione
                      </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div fxLayout="row">
                      <!-- InsertDate field -->
                      <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                          [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                          [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                          <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
                      </mat-form-field>

                      <!-- InsertUser field -->
                      <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                          <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
                      </mat-form-field>
                  </div>
                  <div fxLayout="row">
                      <!-- UpdateDate field -->
                      <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                          [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                          [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                          <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
                      </mat-form-field>

                      <!-- UpdateUser field -->
                      <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                          <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
                      </mat-form-field>
                  </div>
              </mat-expansion-panel>

          </form>
          


          <mat-card-actions>
              <button mat-raised-button [routerLink]="['/interventi-turni-servizi-rep/']" color="primary">Back</button>
              <button mat-raised-button (click)="interventoSave()" [disabled]=" (!form.dirty || form.invalid)  "
                  *ngIf="intervento?.deleteDate==null"> {{ (interventoId == 0 )? 'Save' : 'Update' }}</button>
              <button mat-raised-button (click)="delete()" [disabled]=" this.intervento?.id==null"
               *ngIf="isJappTenantAdmin()">Cancella</button>
          </mat-card-actions>
      </div>

  </mat-card-content>
</mat-card>