import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ResponseQueryByCriteria } from '../../shared/dto/responseQueryByCriteria';
import { Observable } from 'rxjs';
import { OfficeDoorStatusDTO } from 'src/app/shared/dto/domotica/officeDoorStatus';

@Injectable({
  providedIn: 'root'
})

export class DomoticaService {
  private url: String;

  constructor(private http: HttpClient) {
    this.url = environment.urlPrefix + '/services/rest/json/domotica';
  }

  public officeDoorStatus(avoidCache?: boolean): Observable<OfficeDoorStatusDTO> {
    let params = {};
    if (avoidCache != null) params["avoidCache"] = avoidCache;

    return this.http.get<OfficeDoorStatusDTO>(`${this.url}/officeDoor/status`, {
      params: params
    });
  }

  public officeDoorCommand(command: string): Observable<void> {
    return this.http.get<void>(`${this.url}/officeDoor/command/${command}`);
  }

}