import { Component, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { timer, Subject } from 'rxjs';
import { concatMap, switchMap, takeUntil } from 'rxjs/operators';
import { OfficeDoorStatusDTO } from 'src/app/shared/dto/domotica/officeDoorStatus';
import { DomoticaService } from 'src/app/services/domotica/domotica.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { TenantDTO } from 'src/app/shared/dto/domain/tenant';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';

interface Icon {
  code: string;
  colorClass: string;
  statusLabel: string;
}

@Component({
  selector: "app-office-door-widget",
  templateUrl: "./office-door-widget.component.html",
  styleUrls: ["./office-door-widget.component.scss"],
})
export class OfficeDoorWidgetComponent implements OnInit, OnDestroy {
  private timer$ = new Subject<any>();
  private _showWidget: boolean = false;
  private refreshTime: number = 5000;

  tenant: TenantDTO;
  status: OfficeDoorStatusDTO;
  doorIcon: Icon;
  lockIcon: Icon;

  constructor(
    private domoticaService: DomoticaService,
    private navigatorService: NavigatorService,
    private dialog: MatDialog,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIcon("door-closed",   sanitizer.bypassSecurityTrustResourceUrl("assets/img/icons/door-closed.svg"));
    iconRegistry.addSvgIcon("door-open",     sanitizer.bypassSecurityTrustResourceUrl("assets/img/icons/door-open.svg"));
    iconRegistry.addSvgIcon("door-unknown",  sanitizer.bypassSecurityTrustResourceUrl("assets/img/icons/door-unknown.svg"));
    iconRegistry.addSvgIcon("lock-locked",   sanitizer.bypassSecurityTrustResourceUrl("assets/img/icons/lock-locked.svg"));
    iconRegistry.addSvgIcon("lock-unknown",  sanitizer.bypassSecurityTrustResourceUrl("assets/img/icons/lock-unknown.svg"));
    iconRegistry.addSvgIcon("lock-unlocked", sanitizer.bypassSecurityTrustResourceUrl("assets/img/icons/lock-unlocked.svg"));
    iconRegistry.addSvgIcon("lock-warning",  sanitizer.bypassSecurityTrustResourceUrl("assets/img/icons/lock-warning.svg"));
    this.setUnknownStatus();
  }

  ngOnInit() {
    this.navigatorService.tenant.subscribe((res: TenantDTO) => this.tenant = res);

    this.navigatorService.getCustomConfiguration().subscribe((c: any) => {
      if(c?.officeDoorStatusWidgetEnable != null) {
        this._showWidget = c.officeDoorStatusWidgetEnable;
      }
      if(c?.officeDoorStatusWidgetRefreshTime != null) {
        this.refreshTime = c.officeDoorStatusWidgetRefreshTime;
      }

      if(this._showWidget) {
        this.pollStatus();
      }
    });
  }

  get showWidget() {
    return this._showWidget;
  }

  doCommand() {
    const command = this.status?.availableCommand;
    if(command != null) {
      this.confirm(`Sei sicuro di voler ${this.mapCommand(command)} la serratura della porta?`).subscribe(result => {
        if(result) {
          this.domoticaService.officeDoorCommand(command)
            .pipe(concatMap(() => this.domoticaService.officeDoorStatus(true)))
            .subscribe((res: OfficeDoorStatusDTO) => this.elaborateStatus(res));
        }
      });
    }
  }

  private confirm(message: String) {
    return this.dialog.open(ConfirmationDialogComponent, { data: message }).afterClosed();
  }

  private pollStatus() {
    // starts immediately and poll every `refreshTime` milliseconds
    timer(0, this.refreshTime)
      .pipe(
        switchMap(() => this.domoticaService.officeDoorStatus()), // <-- map to another observable
        takeUntil(this.timer$) // <-- close the subscription when `timer$` emits
      )
      .subscribe({
        next: (res: OfficeDoorStatusDTO) => this.elaborateStatus(res),
        error: (error: any) => {
          console.log(error);
          this.setUnknownStatus();
        },
      });
  }

  private elaborateStatus(status: OfficeDoorStatusDTO): void {
    this.status = status;
    this.doorIcon = this.mapDoorIcon(status.doorStatus);
    this.lockIcon = this.mapLockIcon(status.lockStatus);
  }

  private setUnknownStatus(): void {
    this.elaborateStatus({
      doorStatus: null,
      lockStatus: null,
      availableCommand: null
    });
  }

  ngOnDestroy() {
    // close the polling anyway when the component is closed
    this.timer$.next();
  }

  private mapCommand(command: string): string {
    switch (command) {
      case "LOCK":
        return "chiudere";
      case "UNLOCK":
        return "aprire";
      default:
        return "[?]";
    }
  }

  private mapDoorIcon(doorStatus: string): Icon {
    switch (doorStatus) {
      case "OPEN":
        return {
          code: "door-open",
          colorClass: "open",
          statusLabel: "Aperta",
        };
      case "CLOSE":
        return {
          code: "door-closed",
          colorClass: "closed",
          statusLabel: "Chiusa",
        };
      default:
        return {
          code: "door-unknown",
          colorClass: "unknown",
          statusLabel: doorStatus,
        };
    }
  }

  private mapLockIcon(lockStatus: string): Icon {
    switch (lockStatus) {
      case "LOCKED":
        return {
          code: "lock-locked",
          colorClass: "locked",
          statusLabel: "Chiusa",
        };
      case "UNLOCKING":
        return {
          code: "lock-locked",
          colorClass: "locked",
          statusLabel: "In apertura",
        };
      case "UNLOCKED":
        return {
          code: "lock-unlocked",
          colorClass: "unlocked",
          statusLabel: "Aperta",
        };
      case "LOCKING":
        return {
          code: "lock-unlocked",
          colorClass: "unlocked",
          statusLabel: "In chiusura",
        };
      case "LATCHBOLTLOCKED":
        return {
          code: "lock-unlocked",
          colorClass: "unlocked",
          statusLabel: lockStatus,
        };
      case "JAMMED":
        return {
          code: "lock-warning",
          colorClass: "warning",
          statusLabel: "Inceppata",
        };
      default:
        return {
          code: "lock-unknown",
          colorClass: "unknown",
          statusLabel: lockStatus,
        };
    }
  }
}
