<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <div *ngIf="parameters.showList" fxLayout="column">
            <mat-card class="mat-elevation-z0" style="background-color: #f7941d;">
                <mat-card-content fxLayout="row" fxLayoutAlign="space-between center">
                    <!-- Avatar + Nome/Cognome -->
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div fxLayout="column" style="margin-right: 15px;">
                            <ngx-avatar size="65" bgColor="#fef2e4" fgColor="#000000"
                                name="{{collaboratore?.nome}} {{collaboratore?.cognome}}"></ngx-avatar>
                        </div>
                        <div fxLayout="column" style="margin-bottom: -10px;">
                            <mat-card-title style="font-size: 45px;">{{collaboratore?.nome}} {{collaboratore?.cognome}}
                            </mat-card-title>
                            <mat-card-subtitle style="font-size: 30px; margin-top: -15px;">{{ today | date :'dd/MM/yyyy'
                                }}
                            </mat-card-subtitle>
                        </div>
                    </div>
                    <!-- Crtyto-widget -->
                    <div fxLayout="row" fxLayoutGap="16px">
                        <app-crypto-widget *ngIf="isActive('gt-sm')"></app-crypto-widget>
                        <app-office-door-widget></app-office-door-widget>
                    </div>
                </mat-card-content>
            </mat-card>
            <!-- chiusura-aziendale "field" -->
            <mat-card class="mat-elevation-z0">
                <mat-card-content>
                    <div *ngFor="let chiusura of chiusure">
                        <p style="font-size: 20px;">
                            <mat-icon>notification_important</mat-icon>&nbsp;
                            <span *ngIf="!isSameDate(chiusura?.dataDa, chiusura?.dataA)">
                                Dal giorno {{ chiusura?.dataDa | date :'dd/MM/yyyy'}} al giorno {{ chiusura?.dataA |
                                date
                                :'dd/MM/yyyy'}} è prevista
                                una<strong style="font-size: 20px;">&nbsp;chiusura aziendale</strong>.
                            </span>
                            <span *ngIf="isSameDate(chiusura?.dataDa, chiusura?.dataA)">
                                Il giorno {{ chiusura?.dataDa | date :'dd/MM/yyyy'}} è prevista una<strong
                                    style="font-size: 20px;">&nbsp;chiusura aziendale</strong>.
                            </span>
                        </p>
                    </div>
                    <!-- assenza "field" -->
                    <div *ngFor="let assenza of assenze">
                        <p style="font-size: 20px;">
                            <mat-icon>notification_important</mat-icon>&nbsp;
                            <span *ngIf="!isSameDate(assenza?.dataDa, assenza?.dataA); else abcd">
                                Hai delle <strong style="font-size: 20px;">&nbsp;ferie approvate</strong> dal {{
                                assenza?.dataDa |
                                date
                                :'dd/MM/yyyy' }} al
                                {{ assenza?.dataA | date :'dd/MM/yyyy' }}.
                            </span>
                            <ng-template #abcd>
                                <span *ngIf="isSameDate(assenza?.dataDa, assenza?.dataA)">
                                    Hai delle <strong style="font-size: 20px;">&nbsp;ferie approvate</strong> per il {{
                                    assenza?.dataDa | date
                                    :'dd/MM/yyyy' }}.
                                </span>
                            </ng-template>
                        </p>
                    </div>
                </mat-card-content>
            </mat-card>

            <!-- evento "field" -->
            <mat-card class="mat-elevation-z0" *ngIf="eventi?.length > 0">
                <mat-card-content>
                    <div *ngFor="let evento of eventi">
                        <p style="font-size: 20px;">
                            <mat-icon>notification_important</mat-icon>&nbsp;
                            <!-- <span *ngIf="isSameDate(evento.dataOraInizio, evento.dataOraFine); else show_date">
                                Il {{ evento.dataOraInizio | date :'dd/MM/yyyy' }}
                                dalle {{ evento.dataOraInizio | date :'HH:mm' }}
                                alle {{ evento.dataOraFine | date :'HH:mm' }}
                                è previsto un evento: {{ evento.descrizione }}
                            </span>
                            <ng-template #show_date>
                                <span>
                                    Dal {{ evento.dataOraInizio | date :'dd/MM/yyyy' }}
                                    al {{ evento.dataOraFine | date :'dd/MM/yyyy' }}
                                    è previsto un evento: {{ evento.descrizione }}
                                </span>
                            </ng-template> -->
                            <span *ngIf="isSameDate(evento.dataOraInizio, evento.dataOraFine)">
                                Il {{ evento.dataOraInizio | date :'dd/MM/yyyy' }}
                                dalle {{ evento.dataOraInizio | date :'HH:mm' }}
                                alle {{ evento.dataOraFine | date :'HH:mm' }}
                            </span>
                            <span *ngIf="!isSameDate(evento.dataOraInizio, evento.dataOraFine)">
                                Dal {{ evento.dataOraInizio | date :'dd/MM/yyyy' }}
                                al {{ evento.dataOraFine | date :'dd/MM/yyyy' }}
                            </span>
                            <span>
                                è previsto un evento: {{ evento.descrizione }}
                            </span>
                            <a [routerLink]="['/evento/detail/',evento.id]">(Dettagli)</a>
                        </p>
                    </div>
                </mat-card-content>
            </mat-card>


            <!-- postazione-prenotata "field" -->
            <mat-card class="mat-elevation-z0" *ngIf="prenotazionePostazione != null" class="postazionePrenotata"
                [routerLink]="['/prenotazionepostazione/detail/', prenotazionePostazione?.id]">
                <mat-card-content>
                    <p style="font-size: 20px;">
                        <mat-icon>desktop_mac</mat-icon>&nbsp;
                        <span *ngIf="postazionePrenotata != null">
                            Ciao {{ collaboratore.nome }}, oggi hai prenotato la postazione {{postazionePrenotata.descrizione}}
                        </span>
                    </p>
                </mat-card-content>
            </mat-card>

            <!-- jeniacompleanno "field" -->
            <mat-card class="mat-elevation-z0" *ngIf="jeniaCompleanni != null && jeniaCompleanni.length > 1"
                class="postazionePrenotata">
                <mat-card-content>
                    <p style="font-size: 20px;">
                        <mat-icon>star</mat-icon>&nbsp;
                        <span>
                            <span *ngIf="collaboratoreLocalJC == null">
                                Oggi è il Jenia Compleanno di
                                <span *ngFor="let comp of jeniaCompleanni; last as isLast; first as isFirst">
                                    <span *ngIf="isLast"> e {{comp.nome}} {{comp.cognome}}</span>
                                    <span *ngIf="isFirst">{{comp.nome}} {{comp.cognome}}</span>
                                    <span *ngIf="!isLast && !isFirst">, {{comp.nome}} {{comp.cognome}}</span>
                                </span>.
                            </span>
                            <span *ngIf="collaboratoreLocalJC != null">
                                Buon Jenia Compleanno, {{collaboratoreLocalJC.nome}}! Ricorda le paste!
                                Oggi è anche il Jenia Compleanno di
                                <span *ngFor="let comp of jeniaCompleanni; last as isLast; first as isFirst">
                                    <span *ngIf="isLast"> e {{comp.nome}} {{comp.cognome}}</span>
                                    <span *ngIf="isFirst"> {{comp.nome}} {{comp.cognome}}</span>
                                    <span *ngIf="!isLast && !isFirst">, {{comp.nome}} {{comp.cognome}}</span>
                                </span>.
                            </span>
                        </span>
                    </p>
                </mat-card-content>
            </mat-card>

            <mat-card class="mat-elevation-z0" *ngIf="jeniaCompleanni != null && jeniaCompleanni.length == 1"
                class="postazionePrenotata">
                <mat-card-content *ngFor="let comp of jeniaCompleanni">
                    <p style="font-size: 20px;">
                        <mat-icon>star</mat-icon>&nbsp;
                        <span *ngIf="collaboratoreLocalJC == null">
                            Oggi è il Jenia Compleanno di {{comp.nome}} {{comp.cognome}}.
                        </span>&nbsp;
                        <span *ngIf="collaboratoreLocalJC != null">
                            Buon Jenia Compleanno, {{collaboratoreLocalJC.nome}}! Ricorda le paste!
                            Oggi è anche il Jenia Compleanno di {{comp.nome}} {{comp.cognome}}.
                        </span>&nbsp;
                    </p>
                </mat-card-content>
            </mat-card>

            <mat-card class="mat-elevation-z0" *ngIf="jeniaCompleanni.length == 0 && collaboratoreLocalJC != null"
                class="postazionePrenotata">
                <mat-card-content>
                    <p style="font-size: 20px;">
                        <mat-icon>star</mat-icon>&nbsp;
                        <span>
                            Buon Jenia Compleanno, {{collaboratoreLocalJC.nome}}! Ricorda le paste!
                        </span>&nbsp;
                    </p>
                </mat-card-content>
            </mat-card>

            <!-- compleanno "field" -->
            <mat-card class="mat-elevation-z0" *ngIf="compleanniPersonali != null && compleanniPersonali.length > 1"
                class="postazionePrenotata">
                <mat-card-content>
                    <p style="font-size: 20px;">
                        <mat-icon>cake</mat-icon>&nbsp;
                        <span>
                            <span *ngIf="collaboratoreLocalC == null">
                                Oggi è il compleanno di
                                <span *ngFor="let comp of compleanniPersonali; last as isLast; first as isFirst">
                                    <span *ngIf="isLast"> e {{comp.nome}} {{comp.cognome}}</span>
                                    <span *ngIf="isFirst"> {{comp.nome}} {{comp.cognome}}</span>
                                    <span *ngIf="!isLast && !isFirst">, {{comp.nome}} {{comp.cognome}}</span>&nbsp;
                                </span>.
                            </span>
                            <span *ngIf="collaboratoreLocalC != null">
                                Buon compleanno, {{collaboratoreLocalC.nome}}! Ricorda le paste!
                                Oggi è anche il compleanno di
                                <span *ngFor="let comp of compleanniPersonali; last as isLast; first as isFirst">
                                    <span *ngIf="isLast"> e {{comp.nome}} {{comp.cognome}}</span>
                                    <span *ngIf="isFirst"> {{comp.nome}} {{comp.cognome}}</span>
                                    <span *ngIf="!isLast && !isFirst">, {{comp.nome}} {{comp.cognome}}</span>&nbsp;
                                </span>.
                            </span>
                        </span>
                    </p>
                </mat-card-content>
            </mat-card>

            <mat-card class="mat-elevation-z0" *ngIf="compleanniPersonali != null && compleanniPersonali.length == 1"
                class="postazionePrenotata">
                <mat-card-content *ngFor="let comp of compleanniPersonali">
                    <p style="font-size: 20px;">
                        <mat-icon>cake</mat-icon>&nbsp;
                        <span *ngIf="collaboratoreLocalC == null">
                            Oggi è il compleanno di {{comp.nome}} {{comp.cognome}}.
                        </span>
                        <span *ngIf="collaboratoreLocalC != null">
                            Buon compleanno, {{collaboratoreLocalC.nome}}! Ricorda le paste!
                            Oggi è anche il compleanno di {{comp.nome}} {{comp.cognome}}.
                        </span>&nbsp;
                    </p>
                </mat-card-content>
            </mat-card>

            <mat-card class="mat-elevation-z0" *ngIf="compleanniPersonali.length == 0 && collaboratoreLocalC != null"
                class="postazionePrenotata">
                <mat-card-content>
                    <p style="font-size: 20px;">
                        <mat-icon>cake</mat-icon>&nbsp;
                        <span>
                            Buon compleanno, {{collaboratoreLocalC.nome}}! Ricorda le paste!
                        </span>&nbsp;
                    </p>
                </mat-card-content>
            </mat-card>

            <!-- Comunicazione Aziendali Nuove -->
            <mat-accordion>
                <mat-expansion-panel hideToggle
                    *ngIf="comunicazioniAziendaliNascoste != null && comunicazioniAziendaliNascoste.length > 0">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <p>
                                <span [matBadge]="comunicazioniAziendaliNuoveBadgeNumber"
                                    matBadgeOverlap="false">Bacheca</span>
                            </p>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div *ngIf="comunicazioniAziendaliNascoste != null && comunicazioniAziendaliNascoste.length > 0">
                        <div fxLayout="row wrap" fxLayoutAlign="start center">
                            <div *ngFor="let comunicazione of comunicazioniAziendaliNascoste">
                                <mat-card class="mat-card-dimension">
                                    <mat-card-header>
                                        <mat-card-title> {{ comunicazione.titolo }} </mat-card-title>
                                    </mat-card-header>
                                    <mat-card-content>
                                        {{comunicazione.descrizione}}
                                    </mat-card-content>
                                    <mat-card-actions fxLayout="row">
                                        <div fxFlex="<flex-basic>">
                                            <a target="_blank" [href]="comunicazione.url">
                                                <button mat-raised-button color="primary"
                                                    style="transform: scale(0.85);">Vai</button>
                                            </a>
                                        </div>
                                        <div fxFlex="<flex-basic>" fxLayoutAlign="end">
                                            <button mat-raised-button style="transform: scale(0.85);"
                                                (click)="nascondiComunicazione(comunicazione)">Nascondi</button>
                                        </div>
                                    </mat-card-actions>
                                </mat-card>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
                <!-- Comunicazione Personali Nuove -->
                <mat-expansion-panel hideToggle
                    *ngIf="comunicazionePersonaliNascoste!= null && comunicazionePersonaliNascoste.length > 0">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <p>
                                <span [matBadge]="comunicazioniPersonaliNuoveBadgeNumber"
                                    matBadgeOverlap="false">Comunicazioni
                                </span>
                            </p>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div *ngIf="comunicazionePersonaliNascoste != null && comunicazionePersonaliNascoste.length > 0">
                        <div fxLayout="row wrap" fxLayoutAlign="space-evenly center">
                            <div *ngFor="let comunicazione of comunicazionePersonaliNascoste">
                                <mat-card class="mat-card-dimension">
                                    <mat-card-header>
                                        <mat-card-title> {{ comunicazione.titolo }} </mat-card-title>
                                    </mat-card-header>
                                    <mat-card-content>
                                        {{comunicazione.descrizione}}
                                    </mat-card-content>
                                    <mat-card-actions fxLayout="row">
                                        <div fxFlex="<flex-basic>">
                                            <a target="_blank" [href]="comunicazione.url">
                                                <button mat-raised-button color="primary"
                                                    style="transform: scale(0.85);">Vai</button>
                                            </a>
                                        </div>
                                        <div fxFlex="<flex-basic>" fxLayoutAlign="end">
                                            <button mat-raised-button style="transform: scale(0.85);"
                                                (click)="nascondiComunicazione(comunicazione)">Nascondi</button>
                                        </div>
                                    </mat-card-actions>
                                </mat-card>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>

            <div>
                <mat-tab-group mat-stretch-tabs [(selectedIndex)]="selectedIndex">
                    <!-- TAB PREFERITI -->
                    <mat-tab label="Preferiti" *ngIf="showPreferiti()">
                        <app-preferiti (lengthPreferiti)="setLength($event)" [preferiti]="this.myMap"></app-preferiti>
                    </mat-tab>


                    <!-- Generazione Menu -->
                    <mat-tab *ngFor="let menuOne of menu$ | async; index as i;" label="{{menuOne.nome}}">
                        <div fxLayout="row" fxLayoutAlign="space-evenly center">
                            <!-- Bacheca -->
                            <ng-container
                                *ngIf="(menuOne.nome == 'Bacheca' && comunicazioniAziendali != null && comunicazioniAziendali.length > 0)">
                                <mat-card *ngFor="let comunicazione of comunicazioniAziendali"
                                    class="mat-card-dimension">
                                    <mat-card-header>
                                        <mat-card-title> {{ comunicazione.titolo }} </mat-card-title>
                                    </mat-card-header>
                                    <mat-card-content>
                                        <p> {{comunicazione.descrizione}} </p>
                                    </mat-card-content>
                                    <mat-card-actions>
                                        <a target="_blank" [href]="comunicazione.url"><button mat-raised-button
                                                color="primary" style="transform: scale(0.85);">Vai</button></a>
                                    </mat-card-actions>
                                </mat-card>
                            </ng-container>
                            <!-- Comunicazioni personali -->
                            <div
                                *ngIf="menuOne.nome == 'Comunicazioni' && comunicazioniPersonali != null && comunicazioniPersonali.length > 0">
                                <mat-card *ngFor="let comunicazione of comunicazioniPersonali"
                                    class="mat-card-dimension">
                                    <mat-card-header>
                                        <mat-card-title> {{ comunicazione.titolo }} </mat-card-title>
                                    </mat-card-header>
                                    <mat-card-content>
                                        <p> {{comunicazione.descrizione}} </p>
                                    </mat-card-content>
                                    <mat-card-actions>
                                        <a target="_blank" [href]="comunicazione.url"><button mat-raised-button
                                                color="primary" style="transform: scale(0.85);">Vai</button></a>
                                    </mat-card-actions>
                                </mat-card>
                            </div>

                            <div fxLayout="row wrap" fxLayoutAlign="space-evenly center"
                                *ngIf="menuOne.nome == 'Comunicazioni' && comunicazioniPersonali != null && comunicazioniPersonali.length == 0">
                                <mat-card class="mat-card-dimension">
                                    <mat-card-header fxFlex fxFlexAlign="start center">
                                        <mat-card-title> Non hai comunicazioni </mat-card-title>
                                    </mat-card-header>
                                </mat-card>
                            </div>


                            <div fxLayout="row wrap" fxLayoutAlign="space-evenly center"
                                *ngIf="menuOne.nome!='Bacheca' && menuOne.nome!='Comunicazioni'">
                                <mat-card *ngFor="let funzionalitaOne of menuOne.listaFunzionalita; index as j;"
                                    class="button-dimension">
                                    <mat-card-actions *ngIf="isLoggedIn()">
                                        <div fxLayout="row" fxLayoutAlign="end center" class="stella">
                                            <mat-icon *ngIf="getPreferito(funzionalitaOne.nomeFunzionalita) == false"
                                                (click)="modificaPreferito(funzionalitaOne.nomeFunzionalita)">star_border</mat-icon>
                                            <mat-icon *ngIf="getPreferito(funzionalitaOne.nomeFunzionalita) == true"
                                                color="primary"
                                                (click)="modificaPreferito(funzionalitaOne.nomeFunzionalita)">star
                                            </mat-icon>
                                        </div>
                                        <div>
                                            <button *ngIf="funzionalitaOne.nomeFunzionalitaPrint=='Logout'" mat-button
                                                fxFlex (click)="logout()" class="customButtonPadding">
                                                <mat-card-title>Logout</mat-card-title>
                                                <mat-icon class="icon-font-size">exit_to_app</mat-icon>
                                            </button>
                                            <button *ngIf="funzionalitaOne.nomeFunzionalitaPrint!='Logout'"  mat-button fxFlex 
                                                (click)="navigateTo(funzionalitaOne);" class="customButtonPadding">
                                                <mat-card-title style="display: flex; justify-content: center;">{{funzionalitaOne.nomeFunzionalitaPrint}}</mat-card-title>
                                                <mat-icon *ngIf="funzionalitaOne.nomeIcona.startsWith('fas ')" [class]="funzionalitaOne.nomeIcona" class="icon-font-awesome"></mat-icon>
                                                <mat-icon *ngIf="!funzionalitaOne.nomeIcona.startsWith('fas ')" class="icon-font-size">{{funzionalitaOne.nomeIcona}}</mat-icon>
                                            </button>
                                        </div>
                                    </mat-card-actions>
                                </mat-card>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </mat-card-content>
</mat-card>