import { DispositivoDetailComponent } from './dispositivo/dispositivo-detail.component';
import { TurnoAmService } from './services/turno-am/turno-am.service';
import { CollaboratoreAmService } from './services/turno-am/collaboratore-am.service';
import { TipoTurnoAmService } from './services/turno-am/tipo-turno-am.service';
import { OrdineService } from './services/gestione-ordini/ordine.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { PrenotazionePostazioneService } from './services/prenotazionepostazione/prenotazionepostazione.service';
import { PostazioneService } from './services/postazione/postazione.service';
import { ClienteService } from './services/cliente/cliente.service';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ZXingScannerModule } from '@zxing/ngx-scanner';

import { L10nTranslationModule, L10nIntlModule, L10nValidationModule, L10nLoader } from 'angular-l10n';

import { AppMaterialModule } from './app.material.module';
import { AppRoutingModule } from './app.routing.module';
import { OAuthModule } from 'angular-oauth2-oidc';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { AppComponent } from './app.component';
import { NavigatorService } from './services/navigator.service';
import { SalettaService } from './services/saletta/saletta.service';
import { PrenotazioneSalettaService } from './services/prenotazioneSaletta/prenotazione-saletta.service';
import { SedeService } from './services/sede/sede.service';
import { QrcodeService } from './services/qrcode.service';
import { TenantService } from './services/domain/tenant.service';
import { CollaboratoreService } from './services/domain/collaboratore.service';
import { LocationService } from './services/presenze/location.service';
import { NumeroService } from './services/reperibilità/numero.service';
import { DatiAnagraficiSDIService } from './services/fattura-elettronica/datiAnagraficiSDI.service';
import { PresenzaService } from './services/presenze/presenza.service';
import { AssenzaService } from './services/assenza/assenza.service';
import { ChiusuraService } from './services/assenza/chiusura.service';
import { ComunicazioneService } from './services/comunicazioni/comunicazione.service';
import { CreditoService } from './services/credito/credito.service';
import { CatalogoService } from './services/credito/catalogo.service';
import { MovimentoService } from './services/credito/movimento.service';
import { AcquistoService } from './services/acquisti/acquisto.service';
import { PropostaService } from './services/proposte/proposta.service';
import { VotoService } from './services/proposte/voto.service';
import { PrenotazioneService } from './services/prenotazione/prenotazione.service';
import { ComponentCacheService } from './services/component-cache.service';
import { CorsiUdemyService } from './services/corsi-udemy/corsi-udemy.service';
import { FrequentaCorsoService } from './services/corsi-udemy/frequenta-corso.service';
import { FakeAuthenticationHeaderInterceptor } from './commons/fakeAuthenticationHeaderInterceptor';
import { ServerErrorInterceptor } from './commons/serverErrorInterceptor';
import { DateFormatPipe } from './commons/dateFormatPipe';
import { TimestampFormatPipe } from './commons/timestampFormatPipe';
import { AvatarModule } from 'ngx-avatar';
import { GestioneTemplateComponent } from './gestione-template/gestione-template.component';
import { GestioneTemplateDetailComponent } from './gestione-template/gestione-template-detail.component';
import { TorneoComponent } from './torneo/torneo.component';
import { TorneoDetailComponent } from './torneo/torneo-detail.component';

import { l10nConfig, initL10n, AppStorage, HttpTranslationLoader, LocaleValidation } from './l10n-config';
import { NascondiComunicazioneService } from './services/comunicazioni/nascondiComunicazione';
import { RisorsaAziendaleService } from './services/risorsa-aziendale/risorsa-aziendale.service';
import { UrlService } from './commons/calendar/prevUrl.service';
import { GreenPassService } from './services/green-pass/green-pass.service';
import { MalattiaService } from './services/malattia/malattia.service';
import { TorneoService } from './services/torneo/torneo.service';
import { TurnoDetailComponent } from './torneo/turno-detail.component';
import { ComandaService } from './services/gestione-ordini/comanda.service';
import { ColloquioService } from './services/colloquio/colloquio.service';
import { DispositivoComponent } from './dispositivo/dispositivo.component';
import { VisualizzaPresenzeComponent } from './visualizza-presenze/visualizza-presenze.component';
import { ListaAttesaPrenotazionePostazioneService } from './services/lista-attesa-prenotazionepostazione/lista-attesa-prenotazione-postazione-service.service';
import { CompleanniService } from './services/compleanni/compleanni.service';
import { FerieComponent } from './gestione-ferie/ferie.component';
import { NumeriComponent } from './numeri-reperibilità/numeri.component';
import { TurniComponent } from './turni-reperibilità/turni.component';
import { MenuService } from "./services/menu/menu.service";
import { OrarioLavorativoService } from './services/orario-lavorativo/orario-lavorativo.service';
import { Stampante3DService } from './services/stampante3D/stampante3D.service';
import { PrenotazioneStampante3DService } from './services/prenotazione-stampante3D/prenotazione-stampante3D.service';
import { DomoticaService } from './services/domotica/domotica.service';

@NgModule({
  declarations: [
    AppComponent,
    DateFormatPipe,
    TimestampFormatPipe,
    GestioneTemplateComponent,
    GestioneTemplateDetailComponent,
    TorneoComponent,
    TorneoDetailComponent,
    TurnoDetailComponent,
    DispositivoComponent,
    DispositivoDetailComponent,
    VisualizzaPresenzeComponent,
    FerieComponent,
    NumeriComponent,
    TurniComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    HttpClientModule,
    ZXingScannerModule,
    AppMaterialModule,
    AppRoutingModule,
    AvatarModule,
    MatAutocompleteModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    OAuthModule.forRoot({
      resourceServer: {
          sendAccessToken: true
      }
    }),
    L10nTranslationModule.forRoot(
      l10nConfig,
      {
          storage: AppStorage,
          translationLoader: HttpTranslationLoader
      }
    ),
    L10nIntlModule,
    L10nValidationModule.forRoot({ validation: LocaleValidation }),
  ],
  exports: [
    DateFormatPipe,
    TimestampFormatPipe
  ],
  providers: [
    NavigatorService,
    QrcodeService,
    TenantService,
    CollaboratoreService,
    LocationService,
    NumeroService,
    GreenPassService,
    ComandaService,
    OrdineService,
    SalettaService,
    SedeService,
    PrenotazioneSalettaService,
    DatiAnagraficiSDIService,
    PresenzaService,
    AssenzaService,
    ChiusuraService,
    ClienteService,
    CompleanniService,
    ComunicazioneService,
    NascondiComunicazioneService,
    ColloquioService,
    CreditoService,
    CatalogoService,
    MovimentoService,
    AcquistoService,
    PropostaService,
    MalattiaService,
    VotoService,
    PrenotazioneService,
    PrenotazionePostazioneService,
    SedeService,
    ComponentCacheService,
    PostazioneService,
    RisorsaAziendaleService,
    TipoTurnoAmService,
    CollaboratoreAmService,
    TurnoAmService,
    CorsiUdemyService,
    FrequentaCorsoService,
    DateFormatPipe,
    TimestampFormatPipe,
    UrlService,
    TorneoService,
    MenuService,
    ListaAttesaPrenotazionePostazioneService,
    OrarioLavorativoService,
    Stampante3DService,
    PrenotazioneStampante3DService,
    DomoticaService,
    [{
      provide: HTTP_INTERCEPTORS,
      useClass: FakeAuthenticationHeaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true
    }],
    {
      provide: APP_INITIALIZER,
      useFactory: initL10n,
      deps: [L10nLoader],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
