<mat-card *ngIf="showWidget" class="mat-elevation-z0">
    <mat-card-content 
        fxLayout="column" 
        [matTooltip]="'Porta ' + doorIcon.statusLabel + ' | Serratura ' + lockIcon.statusLabel"
        matTooltipPosition="below"
        [ngClass]="{ 'mat-elevation-z5': this.status?.availableCommand != null }"
    >
        <div class="office-door-widget" (click)="doCommand()">
            <mat-icon [svgIcon]="doorIcon.code" class="door-icon" [ngClass]="doorIcon.colorClass"></mat-icon>
            <mat-icon [svgIcon]="lockIcon.code" class="lock-icon" [ngClass]="lockIcon.colorClass"></mat-icon>
        </div>
    </mat-card-content>
</mat-card>