import { Component, OnDestroy, OnInit } from "@angular/core";
import { CollaboratoreDTO } from "../shared/dto/domain/collaboratore";
import { TurnoServiziReperibilitaDTO } from "../shared/dto/gestione-servizi-reperibilita/TurnoServizioReperibilitaDTO";
import { InterventoTurnoReperibilitaDTO } from "../shared/dto/gestione-servizi-reperibilita/InterventoTurnoReperibilitaDTO";
import { ServiziReperibilitaDTO } from "../shared/dto/gestione-servizi-reperibilita/ServizioReperibilitaDTO";
import { TurniServiziReperibilitaService } from "../services/gestione-servizi-reperibilita/turniservizireperibilita.service";
import { CollaboratoreService } from "../services/domain/collaboratore.service";
import { ServiziRepService } from "../services/gestione-servizi-reperibilita/serviziRep.service";
import { ComponentCacheService } from "../services/component-cache.service";
import { NavigatorService } from "../services/navigator.service";
import { ActivatedRoute, Router } from "@angular/router";
import { TimestampFormatPipe } from "../commons/timestampFormatPipe";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DateAdapter } from '@angular/material/core';
import { ResponseQueryByCriteria } from "../shared/dto/responseQueryByCriteria";
import { InterventiReperibilitaService } from "../services/interventi-reperibilita/interventi-reperibilita.service";
import { GenericDetailComponent } from "../shared/GenericDetailComponent";
import { MatDialog } from "@angular/material/dialog";
import { AbstractControl, FormControl, FormGroup, Validators } from "@angular/forms";


@Component({
    selector: 'app-intervento-reperibilita-detail',
    templateUrl: './intervento-reperibilita-detail.component.html',
    styleUrls: ['./intervento-reperibilita.component.scss']
  })
  export class InterventoReperibilitaDetailComponent extends GenericDetailComponent implements OnInit {
    readonly timePickerMin = "00:00";
    readonly timePickerMax = "23:59";
    readonly timePickerGap = "5";
    readonly MAXDATE = new Date(2099, 11, 31)
    
    today: Date
    isNew:boolean
    richiedente: CollaboratoreDTO;
    interventoId: number;
    intervento: InterventoTurnoReperibilitaDTO;
    collaboratori: CollaboratoreDTO[];
    servizi: ServiziReperibilitaDTO[]
    turni: TurnoServiziReperibilitaDTO[]
    mostraFiltroFlag:boolean = false

  
    constructor(
      private turniService: TurniServiziReperibilitaService,
      private collaboratoreService: CollaboratoreService,
      private serviziService: ServiziRepService,
      private interventiService :InterventiReperibilitaService,
      activeRoute: ActivatedRoute,
      dialog: MatDialog,
      snackBar: MatSnackBar,
      timestampFormatPipe: TimestampFormatPipe,
      router: Router,
      navigatorService: NavigatorService,
      dateAdapter: DateAdapter<Date>) {
      super(
        navigatorService,
        dialog,
        router,
        dateAdapter,
        activeRoute,
        snackBar,
        timestampFormatPipe);
      this.form = new FormGroup({
        id: new FormControl({ value: '', disabled: true }),
        collaboratore: new FormControl('', Validators.required),
        tipoServizio: new FormControl('', Validators.required),
        dataDa: new FormControl('', Validators.required),
        dataA: new FormControl('', Validators.required),
        turno: new FormControl('', Validators.required),
        InterventoDataDa: new FormControl('', Validators.required),
        InterventoDataA: new FormControl('', Validators.required),
        orarioDaIntervento:  new FormControl('', Validators.required),
        orarioAIntervento:  new FormControl('', Validators.required),
        descrizione: new FormControl('',Validators.maxLength(1000)),
        deleteDate: new FormControl({ value: '', disabled: true }),
        deleteUser: new FormControl({ value: '', disabled: true }),
        insertDate: new FormControl({ value: '', disabled: true }),
        insertUser: new FormControl({ value: '', disabled: true }),
        updateDate: new FormControl({ value: '', disabled: true }),
        updateUser: new FormControl({ value: '', disabled: true }),
        optLock: new FormControl({ value: '', disabled: true }),
      });
    }
    ngOnInit(): void {
      this.today=new Date()
      this.interventoId = Number(this.activeRoute.snapshot.paramMap.get("id"));

      if(this.interventoId==0 || this.interventoId==null){
        this.initIntervento()

      }
      else{
        this.interventiService.read(this.interventoId).subscribe(res=>{
          this.intervento=res
          this.turni=[this.intervento.turno]
          this.dtoToForm()
        }

        )

      }

      this.collaboratoreService.collaboratoreList(
        0,
        9999,
        'ASC',
        'nome',
        ''
      ).subscribe(
        (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
          this.collaboratori = res.content;
        },
        error => this.errorHandler(error)

      );
      this.serviziService.serviziList(
        0,
        9999,
        'ASC',
        'descrizione',
        '',
        '',
        null).subscribe(
          (res: ResponseQueryByCriteria<ServiziReperibilitaDTO>) => {
            this.servizi = res.content;
          },
          error => this.errorHandler(error)
        );

    }
    initIntervento() {
      this.intervento=new InterventoTurnoReperibilitaDTO
      this.isNew=(this.interventoId==0)
      this.navigatorService.collaboratore.subscribe(
        (res: CollaboratoreDTO) => {
          this.richiedente= res
          this.dtoToForm()
        }
      );

  
    }


    delete(){
      if(this.isJappTenantAdmin()){
        if (this.form.valid && this.isJappTenantAdmin()) {
          this.confirm("Sei sicuro di voler cancellare l'intervento?").subscribe(result => {
            if (result) {
              this.formToDto();
              this.interventiService.delete(this.intervento.id).subscribe(
                (res) => {
                  this.snackBar.open("Operazione avvenuta con successo!", null, { duration: 3000 });
                  this.router.navigate(["interventi-turni-servizi-rep"]);

                },
                error => {
                  this.errorHandler(error)
                }
              );
            }
          });
        }
      }
    }
    interventoSave(){
      
     
      this.formToDto()
      if(this.intervento.dataDa>this.intervento.dataA ||
         this.intervento.dataA>this.today){
        this.snackBar.open("Attenzione dati del intervento incorretti, controllare le date e orari", 'Chiudi', { duration: 5000 });
        return
      }

      if(this.interventoId!=0){
        this.update()
      }
      else(this.create())
      
    }
    update(){
      let msg = this.setMsgToConfirm();
      if (this.form.valid && this.isJappTenantAdmin()) {
        this.confirm(msg).subscribe(result => {
          if (result) {
            this.interventiService.update(this.intervento).subscribe(
              (res) => {
                this.snackBar.open("Operazione avvenuta con successo!", null, { duration: 3000 });
                this.interventiService.read(this.interventoId)
              },
              error => {
                this.errorHandler(error)
              }
            );
          }
        });
      }
    
    }
    
    create(){
      let msg = this.setMsgToConfirm();
      if (this.form.valid) {
        this.confirm(msg).subscribe(result => {
          if (result) {
            this.interventiService.create(this.intervento).subscribe(
              (res) => {
                this.snackBar.open("Operazione avvenuta con successo!", null, { duration: 3000 });
                // this.router.navigate(["interventi-turni-servizi-rep/detail/", res.id])
                this.interventoId=res.id
                this.interventiService.read(this.interventoId)
                this.form.markAsPristine();

              },
              error => {
                this.errorHandler(error)
              }
            );
          }
        });
      }
      
      
    }
    
    private setMsgToConfirm() {
      let msg="Sei sicuro di voler procedere con l'operazione?"
      if (this.intervento.dataDa < this.intervento.turno.dataDa ||
        this.intervento.dataA > this.intervento.turno.dataA) {
        msg = "l' intervento inizia o termina oltre il turno di riferimento! Sei sicuro di voler procedere con l'operazione";
      }
      return msg;
    }



    errorHandler(error: any): void {
      console.log(error)
      this.snackBar.open(error.error.reason, 'Chiudi', { duration: 5000 });
    }



    onFilterChange(){
      if(!!this.form.get("collaboratore").value &&
      !!this.form.get("tipoServizio").value &&
      !!this.form.get("dataDa").value &&
      !!this.form.get("dataA").value 
    ){  this.turni=[]
        let dataDa=new Date(this.form.get("dataDa").value)
        let dataA=new Date(this.form.get("dataA").value)
        this.turniService.turniList(
            0,
            9999,
            "ASC",
            "id",
            this.form.get("collaboratore").value,
            this.form.get("tipoServizio").value,
            dataDa,
            dataA

          
        ).subscribe(res=>{
          if(res.content.length>0){
            this.turni=res.content
          }
        })
      }
    }
    onMostraFilter(){
      this.mostraFiltroFlag=!this.mostraFiltroFlag
    }
    
    private dtoToForm(){
      if(this.isNew){
        this.form.get("collaboratore").setValue(this.richiedente)
        this.form.get("dataDa").setValue(this.today)
        this.form.get("dataA").setValue(this.today)
      }
      console.log(this.isJappTenantAdmin())
      
      if(!this.isJappTenantAdmin() ){
        this.form.get("collaboratore").disable()
        this.form.get("dataDa").disable()
        this.form.get("dataA").disable()
    
      }



      if(!this.isNew){
        this.form.disable()
        this.form.get("id").setValue(this.intervento.id);
        this.form.get("collaboratore").setValue(this.intervento.turno.collaboratore);
        this.form.get("turno").setValue(this.intervento.turno);
        this.form.get("descrizione").setValue(this.intervento.descrizione);
        this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.intervento.insertDate));
        this.form.get("insertUser").setValue(this.intervento.insertUser);
        this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.intervento.updateDate));
        this.form.get("updateUser").setValue(this.intervento.updateUser);
        if(this.isJappTenantAdmin()){
          this.form.get("InterventoDataDa").enable()
          this.form.get("orarioDaIntervento").enable()
          this.form.get("InterventoDataA").enable()
          this.form.get("orarioAIntervento").enable()
          this.form.get("descrizione").enable()

          
        }
        
      }

      if(this.intervento.dataDa){
        this.setDateTimeControl(new Date(this.intervento.dataDa), this.form.get("orarioDaIntervento"), this.form.get("InterventoDataDa"))
        }
      if(this.intervento.dataA){
          this.setDateTimeControl(new Date(this.intervento.dataA), this.form.get("orarioAIntervento"), this.form.get("InterventoDataA"))
        }


    }

    private formToDto(){
      this.intervento.turno=this.form.get("turno").value
      this.intervento.dataDa = this.formToDateDto(this.form.get("orarioDaIntervento"), this.form.get("InterventoDataDa"))
      this.intervento.dataA = this.formToDateDto(this.form.get("orarioAIntervento"), this.form.get("InterventoDataA"));
      this.intervento.descrizione=this.form.get("descrizione").value
    }
    formToDateDto(controlTime: AbstractControl, controlDate: AbstractControl) {
      let tempDate = new Date(controlDate.value)
      let timedate = controlTime.value
      let s = (timedate as string).split(":")
      tempDate.setHours(+s[0])
      tempDate.setMinutes(+s[1])
      return tempDate

    }
    setDateTimeControl(date: Date, controlTime: AbstractControl, controlDate: AbstractControl) {
      controlDate.setValue(this.getNormalizedDate(date));
      controlTime.setValue(this.getNormalizedHour(date));
    }



    private getNormalizedDate(date: Date): Date {
      let tempDate = new Date(date);
      tempDate.setHours(0);
      tempDate.setMinutes(0);
      tempDate.setMilliseconds(0);
      return tempDate
    }

    private getNormalizedHour(date: Date): string {
      let tempdate = new Date(date);
      let hour = tempdate.getHours();
      let minutes = tempdate.getMinutes();
      return hour.toString() + ":" + minutes.toString();
    }
    private IsSameDate(date1: Date,date2: Date){
      return date1.getDate() == date2.getDate()
      && date1.getMonth() == date2.getMonth()
      && date1.getFullYear() == date1.getFullYear()
    }
    isTurnoPresentOrPast(){
      return this.form.get("turno").value.dataDa<=this.today
  
    }

    maxInterventoDateValidator(){
      if(this.form.get("turno").value){
        let date=new Date(this.form.get("turno").value.dataA)
        return (this.today>=date)?date : this.today
      }     
    }
    minInterventoDateValidator(){
      if(this.form.get("turno").value){
        let date=new Date(this.form.get("turno").value.dataDa)
        return date
      }     
    }





    maxTimePickerValidatorFineIntervento(){
      if(this.form.get("turno").value){
        let fineIntervento=this.getNormalizedDate(this.form.get("InterventoDataA").value)
        if (this.IsSameDate(fineIntervento,this.today)){
          return this.getNormalizedHour(this.today)
        }
        return this.timePickerMax
      }
    }



    minTimePickerValidatorFineIntervento(){
      let inizioturno=new Date(this.form.get("turno").value.dataDa)
      let iniziIntervento=new Date(this.form.get("InterventoDataDa").value)
      let fineIntervento=this.getNormalizedDate(this.form.get("InterventoDataA").value)
      
      if (this.IsSameDate(fineIntervento,iniziIntervento)){
        let min :string
        min=this.form.get("orarioDaIntervento").value

        return min

      }
      else if(this.IsSameDate(fineIntervento,inizioturno)){
        return this.getNormalizedHour(inizioturno)
      }

      return this.timePickerMin
      

    }



    maxTimePickerValidatorInizioIntervento(){
        let interventoDateDa=this.getNormalizedDate(this.form.get("InterventoDataDa").value)
        let interventoDateA=this.getNormalizedDate(this.form.get("InterventoDataA").value)
        let inizioTurno=this.getNormalizedDate(this.form.get("turno").value.dataDa)
        if(this.IsSameDate(interventoDateDa,inizioTurno)){
          return this.timePickerMax
        }
        else if(this.IsSameDate(interventoDateDa,interventoDateA)){
          let max :string
          max=this.form.get("orarioAIntervento").value
          return max
        }
        return this.timePickerMax
    }
    minTimePickerValidatorInizioIntervento(){
    if(!!this.form.get("turno").value){
      let normalizedDateDa=this.getNormalizedDate(this.form.get("InterventoDataDa").value)
      let inizioTurno=new Date(this.form.get("turno").value.dataDa)
      if(this.IsSameDate(normalizedDateDa,inizioTurno)){
        return this.getNormalizedHour(inizioTurno)
      }

    }
      return this.timePickerMin
    }

}