import { DispositivoComponent } from './dispositivo/dispositivo.component';
import { lasciaChiaviComponent } from './gestione-chiavi/lasciaChiavi.component';
import { PrendiChiaviComponent } from './gestione-chiavi/prendiChiavi.component';
import { GestioneChiaviComponent } from './gestione-chiavi/gestione-chiavi.component';
import { StoriaChiaviComponent } from './gestione-chiavi/storia-chiavi.component';
import { ChiaviDetailComponent } from './gestione-chiavi/chiaviDetail.component';
import { ChiaviComponent } from './gestione-chiavi/chiavi.component';
import { UfficioComponent } from './ufficio/ufficio.component';
import { SedeDetailComponent } from './sede/sede-detail.component';
import { SedeComponent } from './sede/sede.component';
import { PrenotazionepostazioneDetailComponent } from './prenotazionepostazione/prenotazionepostazione-detail.component';
import { PrenotazionepostazioneComponent } from './prenotazionepostazione/prenotazionepostazione.component';
import { RapportinoFinaleDetailComponent } from './rapportino-finale/rapportino-finale-detail.component';
import { RapportinoFinaleComponent } from './rapportino-finale/rapportino-finale.component';
import { AttivitaComponent } from './attivita/attivita.component';
import { ClienteDetailComponent } from './cliente/cliente-detail.component';
import { ClienteComponent } from './cliente/cliente.component';
import { CalendarHeaderComponent } from './commons/calendar/calendar-header.component';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';

registerLocaleData(localeIt);

import { GestionePrenotazioneSalettaDetailComponent } from './gestione-prenotazione-saletta/gestione-prenotazione-saletta-detail.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppMaterialModule } from './app.material.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { NgxPrintModule } from 'ngx-print';
import { AvatarModule } from 'ngx-avatar';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { ConfirmationDialogComponent } from './shared/confirmation-dialog/confirmation-dialog.component';

import { HomeComponent } from './home/home.component';
import { HelpComponent } from './help/help.component';
import { ErrorComponent } from './error/error.component';
import { TenantComponent } from './tenant/tenant.component';
import { TenantDetailComponent } from './tenant/tenantDetail.component';
import { RegistraPresenzaComponent } from './registra-presenza/registra-presenza.component';
import { LocationComponent } from './location/location.component';
import { LocationDetailComponent } from './location/locationDetail.component';
import { LocationPrintComponent } from './location/locationPrint.component';
import { CollaboratoreDetailComponent } from './collaboratore/collaboratoreDetail.component';
import { CollaboratoreComponent } from './collaboratore/collaboratore.component';
import { RegistraMalattiaComponent } from './registra-malattia/registra-malattia.component';
import { RegistraMalattiaDetailComponent } from './registra-malattia/registra-malattia-detail.component';
import { RegistraAssenzaComponent } from './registra-assenza/registra-assenza.component';
import { RegistraAssenzaDetailComponent } from './registra-assenza/registra-assenza-detail.component';
import { ChiusuraComponent } from './chiusura/chiusura.component';
import { ChiusuraDetailComponent } from './chiusura/chiusura-detail.component';
import { RicercaCollaboratoreComponent } from './ricerca-collaboratore/ricerca-collaboratore.component';
import { GestionePresenzaComponent } from './gestionePresenza/gestionePresenza.component';
import { GestionePresenzaDetailComponent } from './gestionePresenza/gestionePresenzaDetail.component';
import { AboutComponent } from './about/about.component';
import { ComunicazioneComponent } from './comunicazione/comunicazione.component';
import { ComunicazioneDetailComponent } from './comunicazione/comunicazioneDetail.component';
import { GestioneCreditoComponent } from './gestione-credito/gestione-credito.component';
import { MovimentoComponent } from './gestione-credito/movimento/movimento.component';
import { SpesaCreditoComponent } from './gestione-credito/spesa-credito/spesa-credito.component';
import { CaricaCreditoComponent } from './gestione-credito/carica-credito/carica-credito.component';
import { RichiestaAcquistoComponent } from './richiesta-acquisto/richiesta-acquisto.component';
import { RichiestaAcquistoDetailComponent } from './richiesta-acquisto/richiesta-acquisto-detail.component';
import { RimborsoPieDiListaComponent } from './rimborso-pie-di-lista/rimborso-pie-di-lista.component';
import { RimborsoPieDiListaDetailComponent } from './rimborso-pie-di-lista/rimborso-pie-di-lista-detail.component';
import { GestioneCatalogoComponent } from './gestione-credito/gestioneCatalogo/gestioneCatalogo.component';
import { GestioneCatalogoDetailComponent } from './gestione-credito/gestioneCatalogo/gestioneCatalogoDetail.component';
import { GestioneCatalogoPrintComponent } from './gestione-credito/gestioneCatalogo/gestioneCatalogoPrint.component';
import { CryptoWidgetComponent } from './crypto-widget/crypto-widget.component';
import { OfficeDoorWidgetComponent } from './domotica/office-door-widget/office-door-widget.component';
import { PropostaComponent } from './cosa-mangi-oggi/proposta.component';
import { PropostaDetailComponent } from './cosa-mangi-oggi/proposta-detail.component';
import { GestionePrenotazioneComponent } from './gestionePrenotazione/gestionePrenotazione.component';
import { GestionePrenotazioneDetailComponent } from './gestionePrenotazione/gestionePrenotazioneDetail.component';
import { GestioneTemplateComponent } from './gestione-template/gestione-template.component';
import { GestioneTemplateDetailComponent } from './gestione-template/gestione-template-detail.component';
import { NgxQRCodeComponent } from './ngx-qrcode2/ngx-qrcode2.component';
import { CambioPasswordComponent } from './cambio-password/cambioPassword.component';
import { DatiAnagraficiComponent } from './dati-anagrafici/dati-anagrafici.component';
import { PostazioneComponent } from './postazione/postazione.component';
import { PostazioneDetailComponent } from './postazione/postazione-detail.component';
import { UfficioDetailComponent } from './ufficio/ufficio-detail.component';
import { AttivitaDetailComponent } from './attivita/attivita-detail.component';
import { GestioneSalettaComponent } from './gestione-saletta/gestione-saletta.component';
import { GestioneSalettaDetailComponent } from './gestione-saletta/gestione-saletta-detail.component';
import { GestionePrenotazioneSalettaComponent } from './gestione-prenotazione-saletta/gestione-prenotazione-saletta.component';
import { CalendarioComponent } from './calendario/calendario.component';
import { RisorsaAziendaleComponent } from './risorsa-aziendale/risorsa-aziendale.component';
import { RisorsaAziendaleDetailComponent } from './risorsa-aziendale/risorsa-aziendale-detail.component';
import { RisorseAziendaliComponent } from './risorse-aziendali/risorse-aziendali.component';
import { PreferitiComponent } from './preferiti/preferiti.component';
import { TipoTurnoAmDetailComponent } from './tipo-turno-am/tipo-turno-am-detail.component';
import { TipoTurnoAmComponent } from './tipo-turno-am/tipo-turno-am.component';
import { CollaboratoreAmComponent } from './collaboratore-am/collaboratore-am.component';
import { CollaboratoreAmDetailComponent } from './collaboratore-am/collaboratore-am-detail.component';
import { TurnoAmComponent } from './turno-am/turno-am.component';
import { TurnoAmDetailComponent } from './turno-am/turno-am-detail.component';
import { TurnoAmSwapComponent } from './turno-am/turno-am-swap.component';
import { TurnoAmCopyComponent } from './turno-am/turno-am-copy.component';
import { TorneoComponent } from './torneo/torneo.component';
import { TorneoDetailComponent } from './torneo/torneo-detail.component';
import { TurnoDetailComponent } from './torneo/turno-detail.component';
import { GestioneComandaComponent } from './gestione-comanda/gestione-comanda.component';
import { GestioneComandaDetailComponent } from './gestione-comanda/gestione-comanda-detail.component';
import { GestioneOrdineComponent } from './gestione-ordine/gestione-ordine.component';
import { GestioneOrdineDetailComponent } from './gestione-ordine/gestione-ordine-detail.component';
import { GestionePagamentoComponent } from './gestione-comanda/gestione-pagamento.component';
import { CorsiUdemyComponent } from './corsi-udemy/corsi-udemy.component';
import { CorsiUdemyDetailComponent } from './corsi-udemy/corsi-udemy-detail.component';
import { FrequentaCorsoComponent } from './frequenta-corso/frequenta-corso.component';
import { FrequentaCorsoDetailComponent } from './frequenta-corso/frequenta-corso-detail.component';
import { CalendarioAmministrazioneComponent } from './calendario-amministrazione/calendario-amministrazione.component';
import { MioProfiloComponent } from './mio-profilo/mio-profilo.component';
import { ColloquioComponent } from './colloquio/colloquio.component';
import { ColloquioDetailComponent } from './colloquio/colloquioDetail.component';
import { DispositivoDetailComponent } from './dispositivo/dispositivo-detail.component';
import { VisualizzaPresenzeComponent } from './visualizza-presenze/visualizza-presenze.component';
import { ListaAttesaPrenotazionepostazioneDetailComponent } from './lista-attesa-prenotazionepostazione/listaattesaprenotazionepostazione-detail.component';
import { ListaAttesaPrenotazionepostazioneComponent } from './lista-attesa-prenotazionepostazione/listaattesaprenotazionepostazione.component';
import { FerieComponent } from './gestione-ferie/ferie.component';
import { FerieDetailComponent } from './gestione-ferie/ferieDetail.component';
import { NumeriComponent } from './numeri-reperibilità/numeri.component';
import { NumeriDetailComponent } from './numeri-reperibilità/numeriDetail.component';
import { TurniComponent } from './turni-reperibilità/turni.component';
import { TurniDetailComponent } from './turni-reperibilità/turniDetail.component';
import { OrarioLavorativoComponent } from './orario-lavorativo/orario-lavorativo.component';
import { OrarioLavorativoDetailComponent } from './orario-lavorativo/orario-lavorativo-detail.component';
import { OrarioLavorativoDTO } from './shared/dto/orario-lavorativo/orario-lavorativo';
import { OrarioLavorativoDuplicateComponent } from './orario-lavorativo/orario-lavorativo-duplicate.component';
import { GestioneServiziComponent } from './reperibilita-turni-servizi/gestione-servizi-rep.component';
import { GestioneServiziDetailComponent } from './reperibilita-turni-servizi/gestione-servizi-detail.component';
import { GestioneTurniServiziComponent } from './reperibilita-turni-servizi/gestione-turni-servizi-rep.component';
import { GestioneTurniServiziDetailComponent } from './reperibilita-turni-servizi/gestione-turni-servizi-detail.component';
import { PrenotazionePostazioneDeleteComponent } from './prenotazionepostazione/prenotazionepostazione-delete.component';
import { InterventoReperibilitaComponent } from './intervento-reperibilita/intervento-reperibilita-.component';
import { InterventoReperibilitaDetailComponent } from './intervento-reperibilita/intervento-reperibilita-detail.component';
import { PrenotazioneStampante3DComponent } from './prenotazione-stampante3D/prenotazione-stampante3D.component';
import { Stampante3DComponent } from './stampante3D/stampante3D.component';
import { Stampante3DDetailComponent } from './stampante3D/stampante3D-detail.component';
import { PrenotazioneStampante3DDetailComponent } from './prenotazione-stampante3D/prenotazione-stampante3D-detail.component';
import { TurniSostituzioneMassivaComponent } from './turni-reperibilità/turniDetailMultiSostituzione.component';
import { EventoComponent } from './evento/evento.component';
import { EventoDetailComponent } from './evento/evento-detail.component';
import { PartecipanteEventoComponent } from './evento/partecipante-evento.component';
import { PartecipanteEventoDetailComponent } from './evento/partecipante-evento-detail.component';
import { CategoriaComponent } from './evento/categoria/categoria.component';
import { CategoriaDetailComponent } from './evento/categoria-detail/categoria-detail.component';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'help', component: HelpComponent},
  {path: 'about', component: AboutComponent},
  {path: 'risorse-aziendali', component: RisorseAziendaliComponent},
  {path: 'tenant', component: TenantComponent},
  {path: 'tenant/detail/:id', component: TenantDetailComponent},  
  {path: 'registra-presenza', component: RegistraPresenzaComponent},
  {path: 'rapportino-finale', component: RapportinoFinaleComponent},
  {path: 'rapportino-finale/detail/:id', component: RapportinoFinaleDetailComponent},
  {path: 'cliente', component: ClienteComponent},
  {path: 'cliente/detail/:id', component: ClienteDetailComponent},
  {path: 'attivita', component: AttivitaComponent},
  {path: 'attivita/detail/:id', component: AttivitaDetailComponent},
  {path: 'chiavi', component: ChiaviComponent},
  {path: 'storia-chiavi', component: StoriaChiaviComponent},
  {path: 'chiavi/detail/:id', component: ChiaviDetailComponent},
  {path: 'gestione-chiavi/prendi-chiavi/:idChiavi', component: PrendiChiaviComponent},
  {path: 'gestione-chiavi/lascia-chiavi/:idChiavi', component: lasciaChiaviComponent},
  {path: 'gestione-chiavi', component: GestioneChiaviComponent},
  {path: 'location', component: LocationComponent},
  {path: 'location/detail/:id', component: LocationDetailComponent},
  {path: 'location/print/:id', component: LocationPrintComponent},
  {path: 'sede', component: SedeComponent},
  {path: 'sede/detail/:id', component: SedeDetailComponent},
  {path: 'saletta', component: GestioneSalettaComponent},
  {path: 'saletta/detail/:id', component: GestioneSalettaDetailComponent},
  {path: 'gestione-prenotazione-saletta', component: GestionePrenotazioneSalettaComponent},
  {path: 'gestione-prenotazione-saletta/detail/:id', component: GestionePrenotazioneSalettaDetailComponent},
  {path: 'collaboratore', component: CollaboratoreComponent},
  {path: 'collaboratore/detail/:id', component: CollaboratoreDetailComponent},
  {path: 'registra-malattia', component: RegistraMalattiaComponent},
  {path: 'registra-malattia/detail/:id', component: RegistraMalattiaDetailComponent},
  {path: 'calendario-amministrazione', component: CalendarioAmministrazioneComponent},
  {path: 'registra-assenza', component: RegistraAssenzaComponent},
  {path: 'registra-assenza/detail/:id', component: RegistraAssenzaDetailComponent},
  {path: 'evento', component: EventoComponent},
  {path: 'evento/detail/:id', component: EventoDetailComponent},
  {path: 'partecipante-evento', component: PartecipanteEventoComponent},
  {path: 'partecipante-evento/detail/:id', component: PartecipanteEventoDetailComponent},
  {path: 'categoria-evento', component: CategoriaComponent},
  {path: 'categoria-evento/detail/:id', component: CategoriaDetailComponent},
  {path: 'chiusura', component: ChiusuraComponent},
  {path: 'chiusura/detail/:id', component: ChiusuraDetailComponent},
  {path: 'dove-sei', component: RicercaCollaboratoreComponent},
  {path: 'comunicazione', component: ComunicazioneComponent},
  {path: 'comunicazione/detail/:id', component: ComunicazioneDetailComponent},
  {path: 'colloquio', component: ColloquioComponent},
  {path: 'colloquio/detail/:id', component: ColloquioDetailComponent},
  {path: 'gestione-presenza', component: GestionePresenzaComponent},
  {path: 'gestione-presenza/detail/:id', component: GestionePresenzaDetailComponent},
  {path: 'gestione-comanda', component: GestioneComandaComponent},
  {path: 'gestione-ordine', component: GestioneOrdineComponent},
  {path: 'gestione-ordine/detail/:id', component: GestioneOrdineDetailComponent},
  {path: 'gestione-comanda/detail/:id', component: GestioneComandaDetailComponent},
  {path: 'gestione-pagamento/:id', component: GestionePagamentoComponent},
  {path: 'gestione-credito', component: GestioneCreditoComponent},
  {path: 'gestione-credito/movimenti/:id', component: MovimentoComponent},
  {path: 'gestione-credito/acquisto/:id', component: SpesaCreditoComponent},
  {path: 'gestione-credito/ricarica/:id', component: CaricaCreditoComponent},
  {path: 'richiesta-acquisto', component: RichiestaAcquistoComponent},
  {path: 'richiesta-acquisto/detail/:id', component: RichiestaAcquistoDetailComponent},
  {path: 'rimborso-pie-di-lista', component: RimborsoPieDiListaComponent},
  {path: 'rimborso-pie-di-lista/detail/:id', component: RimborsoPieDiListaDetailComponent},
  {path: 'gestione-catalogo', component: GestioneCatalogoComponent},
  {path: 'gestione-catalogo/detail/:id', component: GestioneCatalogoDetailComponent},
  {path: 'gestione-catalogo/print/:id', component: GestioneCatalogoPrintComponent},
  {path: 'proposta', component: PropostaComponent},
  {path: 'proposta/detail/:id', component: PropostaDetailComponent},
  {path: 'gestione-prenotazione', component: GestionePrenotazioneComponent},
  {path: 'gestione-prenotazione/detail/:id', component: GestionePrenotazioneDetailComponent},
  {path: 'gestione-template', component: GestioneTemplateComponent},
  {path: 'gestione-template/detail/:id', component: GestioneTemplateDetailComponent},
  {path: 'cambio-password', component: CambioPasswordComponent},
  {path: 'dati-anagrafici', component: DatiAnagraficiComponent},
  {path: 'mio-profilo', component: MioProfiloComponent},
  {path: 'tipo-turno-am', component: TipoTurnoAmComponent},
  {path: 'tipo-turno-am/detail/:id', component: TipoTurnoAmDetailComponent},
  {path: 'collaboratore-am', component: CollaboratoreAmComponent},
  {path: 'collaboratore-am/detail/:id', component: CollaboratoreAmDetailComponent},
  {path: 'turno-am', component: TurnoAmComponent},
  {path: 'turno-am/detail/:id', component: TurnoAmDetailComponent},
  {path: 'turno-am/swap/:id', component: TurnoAmSwapComponent},
  {path: 'turno-am/copy', component: TurnoAmCopyComponent},
  {path: 'ferie', component: FerieComponent} ,
  {path: 'ferie/detail/:id', component: FerieDetailComponent},
  {path: 'numeri-reperibilita', component: NumeriComponent},
  {path: 'numeri-reperibilita/detail/:id', component: NumeriDetailComponent},
  {path: 'turni-reperibilita',  component: TurniComponent},
  {path: 'turni-reperibilita/detail/:id',  component: TurniDetailComponent},
  {path: 'postazione', component: PostazioneComponent},
  {path: 'postazione/detail/:id', component: PostazioneDetailComponent},
  {path: 'prenotazionepostazione', component: PrenotazionepostazioneComponent},
  {path: 'prenotazionepostazione/deletemassiva', component: PrenotazionePostazioneDeleteComponent},
  {path: 'prenotazionepostazione/detail/:id', component: PrenotazionepostazioneDetailComponent},
  {path: 'sede', component: SedeComponent},
  {path: 'sede/detail/:id', component: SedeDetailComponent},
  {path: 'ufficio', component: UfficioComponent},
  {path: 'ufficio/detail/:id', component: UfficioDetailComponent},
  {path: 'calendario', component: CalendarioComponent},
  {path: 'risorsa-aziendale', component: RisorsaAziendaleComponent},
  {path: 'risorsa-aziendale/detail/:id', component: RisorsaAziendaleDetailComponent},
  {path: 'torneo', component: TorneoComponent},
  {path: 'torneo/detail/:id', component: TorneoDetailComponent},
  {path: 'turno/detail/:id', component: TurnoDetailComponent},
  {path: 'corsoudemy', component: CorsiUdemyComponent},
  {path: 'corsoudemy/detail/:id', component: CorsiUdemyDetailComponent},
  {path: 'frequentacorso', component: FrequentaCorsoComponent},
  {path: 'frequentacorso/detail/:id', component: FrequentaCorsoDetailComponent},
  {path: 'rapportino-finale', component: RapportinoFinaleComponent},
  {path: 'dispositivi', component: DispositivoComponent},
  {path: 'dispositivi/detail/:id', component: DispositivoDetailComponent},
  {path: 'visualizza-presenze', component: VisualizzaPresenzeComponent},
  {path: 'listaattesaprenotazionepostazione', component: ListaAttesaPrenotazionepostazioneComponent},
  {path: 'listaattesaprenotazionepostazione/detail/:id', component: ListaAttesaPrenotazionepostazioneDetailComponent},
  {path: 'orario-lavorativo', component: OrarioLavorativoComponent},
  {path: 'orario-lavorativo/detail/:id', component: OrarioLavorativoDetailComponent},
  {path: 'orario-lavorativo/duplicate/:id', component: OrarioLavorativoDuplicateComponent},
  {path: 'gestione-servizi-rep', component : GestioneServiziComponent},
  {path: 'gestione-servizi-rep/detail/:id', component : GestioneServiziDetailComponent},
  {path: 'gestione-turni-servizi-rep', component : GestioneTurniServiziComponent},
  {path: 'gestione-turni-servizi-rep/detail/:id', component : GestioneTurniServiziDetailComponent},
  {path: 'interventi-turni-servizi-rep', component : InterventoReperibilitaComponent },
  {path: 'interventi-turni-servizi-rep/detail/:id', component : InterventoReperibilitaDetailComponent},
  {path: 'stampante3d', component: Stampante3DComponent },
  {path: 'stampante3d/detail/:id', component: Stampante3DDetailComponent },
  {path: 'prenotazionestampante3d', component: PrenotazioneStampante3DComponent },
  {path: 'prenotazionestampante3d/detail/:id', component: PrenotazioneStampante3DDetailComponent },
  {path: 'turni-reperibilita/sostituzione-massiva/:id',  component: TurniSostituzioneMassivaComponent},

  {path: '**', component: ErrorComponent}
];

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    AppMaterialModule,
    ZXingScannerModule,
    NgxPrintModule,
    AvatarModule,
    RouterModule.forRoot(routes)
  ],
  declarations: [
    AttivitaComponent,
    AttivitaDetailComponent,
    RapportinoFinaleComponent,
    RapportinoFinaleDetailComponent,
    ConfirmationDialogComponent,
    HomeComponent,
    HelpComponent,
    RisorseAziendaliComponent,
    AboutComponent,
    ErrorComponent,
    TenantComponent,    
    RegistraPresenzaComponent,
    TenantDetailComponent,
    LocationComponent,
    LocationDetailComponent,
    LocationPrintComponent,
    SedeComponent,
    SedeDetailComponent,
    CollaboratoreComponent,
    CollaboratoreDetailComponent,
    RegistraMalattiaComponent,
    RegistraMalattiaDetailComponent,
    CalendarioAmministrazioneComponent,
    RegistraAssenzaComponent,
    EventoComponent,
    EventoDetailComponent,
    PartecipanteEventoComponent, 
    PartecipanteEventoDetailComponent,
    CategoriaDetailComponent,
    CategoriaComponent,
    RegistraAssenzaDetailComponent,
    ChiusuraComponent,
    ChiusuraDetailComponent,
    GestionePresenzaComponent,
    GestionePresenzaDetailComponent,
    GestioneComandaComponent,
    GestioneComandaDetailComponent,
    GestionePagamentoComponent,
    GestioneOrdineComponent,
    GestioneOrdineDetailComponent,
    RicercaCollaboratoreComponent,
    RimborsoPieDiListaComponent,
    RimborsoPieDiListaDetailComponent,
    ComunicazioneComponent,
    ComunicazioneDetailComponent,
    ColloquioComponent,
    ColloquioDetailComponent,
    GestioneCreditoComponent,
    MovimentoComponent,
    SpesaCreditoComponent,
    CaricaCreditoComponent,
    RichiestaAcquistoComponent,
    RichiestaAcquistoDetailComponent,
    ClienteComponent,
    ClienteDetailComponent,
    GestioneCatalogoComponent,
    GestioneCatalogoDetailComponent,
    GestioneCatalogoPrintComponent,
    CryptoWidgetComponent,
    OfficeDoorWidgetComponent,
    PropostaComponent,
    PropostaDetailComponent,
    GestionePrenotazioneComponent,
    GestionePrenotazioneDetailComponent,
    GestioneSalettaComponent,
    CambioPasswordComponent,
    DatiAnagraficiComponent,
    SedeComponent,
    SedeDetailComponent,
    PostazioneDetailComponent,
    PostazioneComponent,
    PrenotazionepostazioneDetailComponent,
    PrenotazionepostazioneComponent,
    UfficioDetailComponent,
    UfficioComponent,
    NgxQRCodeComponent,
    GestioneSalettaDetailComponent,
    GestionePrenotazioneSalettaComponent,
    GestionePrenotazioneSalettaDetailComponent,
    CalendarHeaderComponent,
    CalendarioComponent,
    RisorsaAziendaleComponent,
    RisorsaAziendaleDetailComponent,
    PreferitiComponent,
    TipoTurnoAmComponent,
    TipoTurnoAmDetailComponent,
    CollaboratoreAmComponent,
    CollaboratoreAmDetailComponent,
    TurnoAmComponent,
    TurnoAmDetailComponent,
    TurnoAmSwapComponent,
    TurnoAmCopyComponent,
    CorsiUdemyComponent,
    CorsiUdemyDetailComponent,
    FrequentaCorsoComponent,
    FrequentaCorsoDetailComponent,
    MioProfiloComponent,
    ChiaviComponent,
    ChiaviDetailComponent,
    StoriaChiaviComponent,
    GestioneChiaviComponent,
    PrendiChiaviComponent,
    lasciaChiaviComponent,
    ListaAttesaPrenotazionepostazioneComponent,
    ListaAttesaPrenotazionepostazioneDetailComponent,
    FerieDetailComponent,
    NumeriDetailComponent,
    TurniDetailComponent,
    PrenotazionePostazioneDeleteComponent,
    GestioneServiziComponent,
    GestioneServiziDetailComponent,
    GestioneTurniServiziComponent,
    GestioneTurniServiziDetailComponent,
    InterventoReperibilitaComponent,
    InterventoReperibilitaDetailComponent,
    OrarioLavorativoComponent,
    OrarioLavorativoDetailComponent,
    OrarioLavorativoDuplicateComponent,
    Stampante3DComponent,
    Stampante3DDetailComponent,
    PrenotazioneStampante3DComponent,
    PrenotazioneStampante3DDetailComponent,
    TurniSostituzioneMassivaComponent
  ],
  entryComponents: [
    ConfirmationDialogComponent
  ],
  exports: [
    RouterModule,
    CalendarHeaderComponent
  ]
})
export class AppRoutingModule {
}
